import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {HeaderService} from "../../../services/header.service";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  lets = false;
  step = 1;
  errorSee = false;
  error = {
    name: '',
    email: '',
    company: '',
  }
  fields = {
    name: '',
    email: '',
    company: '',
    tell: ''
  };
  constructor(
      private headerSerive : HeaderService
  ) { }

  ngOnInit() {
    this.headerSerive.onLets.subscribe((data) => {
      this.lets = data;
    })
  }


  checkForm(bool){
    let countError = 0;
    this.errorSee = true;
    if(this.fields.name.length <2){
      this.error.name = 'Field in not correctly';
      countError++;
    }else{
      this.error.name = '';
    }
    if(this.fields.company.length <2){
      this.error.company = 'Field in not correctly';
      countError++;
    }else{
      this.error.company = '';
    }
    if(!this.isEmail(this.fields.email)){
      this.error.email = 'Field in not correctly';
      countError++;
    }else{
      this.error.email = '';
    }
    if(bool && countError==0){
      $.ajax({
        type: "POST",
        url: "/mail.php",
        data: {
          name: this.fields.name,
          email: this.fields.email,
          company: this.fields.company,
          tell: this.fields.tell,
        },
        success: function (result) {
          this.step = 2;
        }
      });
    }
  }

  isEmail(email) {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.trim());
  }

  inputVal( field, value){
    this.fields[field] = value;
    if(this.errorSee){
      this.checkForm(false);
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import {ClientsComponent} from "./clients/clients.component";
import {SliderComponent} from "./slider/slider.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
	  FooterComponent, 
	  HeaderComponent,
      ClientsComponent,
      SliderComponent
  ],
  exports: [
     FooterComponent, 
     HeaderComponent,
      ClientsComponent,
      SliderComponent
  ]
})
export class SharedModule { }

import {Component} from '@angular/core';
declare const gsap: any;
declare const ScrollTrigger: any;

@Component({
  selector: 'app-root',
  template: `
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  `,
  styles: []
})

export class AppComponent {

}

import { Component, OnInit, Input } from '@angular/core';
import {HeaderService} from '../../../services/header.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.sass']
})
export class ClientsComponent implements OnInit {
  @Input() title: any;
  countryAuto: any;
  awwards = [
      '/assets/images/icon-awwards/1.svg',
      '/assets/images/icon-awwards/2.svg',
      '/assets/images/icon-awwards/3.svg',
      '/assets/images/icon-awwards/4.svg',
      '/assets/images/icon-awwards/5.svg',
  ];
  activeCountry = 0;
  country = [
    'USA',
    'United Kingdom',
    'France',
    'Ukraine',
    'Canada',
    'Switzerland',
    'Germany',
    'Spain',
    'Czech Republic',
    'Italy',
    'Qatar',
    'India',
    'Israel',
    'Poland'
  ]
  activeLink = 0;
  review = [
    {
      name: "Dariia Skurikhina",
      position: 'Marketing Manager, Motul',
      photo: '/assets/images/people3.png',
      text: 'They acted like advisors, sharing their expertise for better decisions and solutions.',
      link: 'https://clutch.co/profile/sevn-studio#review-1987545'
    },
    {
      name: "Olga Mazur",
      position: 'Leader of Communications Team, Tabletochki',
      photo: '/assets/images/people2.png',
      text: 'They provide alternative solutions while caring about the results.',
      link: 'https://clutch.co/profile/sevn-studio#review-2040703'
    },
    {
      name: "Kateryna Gaieva",
      position: 'PR Director, CMS Group',
      photo: '/assets/images/people1.png',
      text: 'They were much more efficient site builders than I expected.',
      link: 'https://clutch.co/profile/sevn-studio#review-1527849'
    }
  ];

  constructor(
      private headerSerive : HeaderService,
      private router: Router,
  ) { }

  ngOnInit() {
    this.countryAuto = setInterval(()=>{
      this.countrySlide();
    }, 2000)
  }

  sliderTo(index) {
    this.activeLink = index;
    $('.clients__nav div').removeClass('active');
    $('.clients__nav div:nth-child(' + (index + 1) + ')').addClass('active');
    $('.clients__items').css('transform' , 'translateX(-' + (index * 100) + '%)');
  }

  clientsNext() {
    let index = $('.clients__nav div.active').index();
    if(index+1 >=this.review.length){
      index = 0;
    }else{
      index++;
    }
    $('.clients__nav div').removeClass('active');
    $('.clients__nav div:nth-child(' + (index+1) + ')').addClass('active');
    $('.clients__items').css('transform' , 'translateX(-' + (index * 100) + '%)');
  }

  clientsPrev() {
    let index = $('.clients__nav div.active').index();
    if(index<1){
      index = this.review.length - 1;
    }else{
      index--;
    }
    $('.clients__nav div').removeClass('active');
    $('.clients__nav div:nth-child(' + (index+1) + ')').addClass('active');
    $('.clients__items').css('transform' , 'translateX(-' + (index * 100) + '%)');
  }


  awwardsSlide(){
    if($(window).width()<=500 || $(window).width()>=1024 ) {
      let element = $('.clients__awwards--list>div:nth-child(1)');
      $(element).animate({'margin-left': '-' + (element.width() + 16) + 'px'}, 300, function () {
        $(this).appendTo('.clients__awwards--list').css('margin-left', '0');
      });
    }
  }


  awwardsSlidePrev(){
    if($(window).width()<=400 || $(window).width()>=769 ) {
      let element = $('.clients__awwards--list>div:last-child');
      $(element).css('margin-left', '-' + (element.width() + 16) + 'px').prependTo('.clients__awwards--list').animate({'margin-left': '0px'}, 300);
    }
  }

  countrySlide(){
    let index = $('.clients__country').find('.active').index();
    $('.clients__country').find('span').removeClass('active');
    if((index + 1) > this.country.length - 1){
      this.activeCountry = 0;
    }else{
      this.activeCountry++;
    }
  }

  openHash() {
    this.headerSerive.shadowPopup(false);
    setTimeout(()=>{
      this.router.navigate( ['/about'], {fragment: 'awwards'});
    }, 500);
  }


}

import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
import {HeaderService} from "../../../services/header.service";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.sass']
})
export class SliderComponent implements OnInit {
  @Input() data: any;
  clickSlide = false;

  constructor(
      private headerSerive : HeaderService
  ) { }

  ngOnInit() {

  }


  sliderLeft(index){
      if(!this.clickSlide) {
          this.clickSlide = true;
          let list = $('.slider__list[data-list="' + index + '"]');
          let element = list.find('.slider__item:last-child');
          $(element).css('margin-left', '-' + (parseInt(element.css('width').split('px')[0]) + 16) + 'px').prependTo(list).animate({'margin-left': '0px'}, 300, ()=> {
              this.clickSlide = false;
          });
      }
  }

  sliderRight(index){
      if(!this.clickSlide) {
          this.clickSlide = true;
          let list = $('.slider__list[data-list="' + index + '"]');
          let element = list.find('.slider__item:first-child');
          $(element).animate({'margin-left': '-' + (parseInt(element.css('width').split('px')[0]) + 16) + 'px'}, 300, ()=> {
              $(element).appendTo('.slider__list[data-list="' + index + '"]').css('margin-left', '0');
              this.clickSlide = false;
          });
      }
  }

  activeElement(data, index){
    data.active = index;
  }

  openPopup(){
      this.headerSerive.letsPopup(true);
      let top = $('footer').offset().top;
      $('body,html').animate({scrollTop: top}, 500);
  }

}

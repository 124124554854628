import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    constructor(private _http: HttpClient) {
    }
    private clickBlack: any;
    private openLets: any;

    onClick: EventEmitter<any> = new EventEmitter();
    onLets: EventEmitter<any> = new EventEmitter();

    public shadowPopup(bool) {
        this.clickBlack = bool;
        this.onClick.emit(this.clickBlack);
    }

    public letsPopup(bool) {
        this.openLets = bool;
        this.onLets.emit(this.openLets);
    }

}

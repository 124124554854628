import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../../services/header.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  open = false;
  load = false;
  constructor(
      private headerSerive : HeaderService
  ) { }

  ngOnInit() {
    this.headerSerive.onClick.subscribe((data) => {
      this.load = data;
    })
    setTimeout(()=>{
      this.load = true;
    }, 1000)
  }

  openMenu(){
    $('body').toggleClass('disable-scroll');
    this.open = !this.open;
  }

}
